import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { typeStyle } from '@carvana/showroom-css-theme';
import NotFoundLayout from '@carvana/wsle-not-found-layout';
import { flexAlignHorizontalContent, flexAlignVerticalContent, mq, DESKTOP } from '../styles/mixins';
import { DocReview, MoneyKeys, Target } from './icons';
import { NotFoundLayoutWrapper } from './LDP/styles';

const PageNotFoundGraphics = () => {
  return (
    <Container>
      <IconContainer>
        <DocReview />
        <p><FormattedMessage id='NOT_FOUND_REPORTS' /></p>
      </IconContainer>
      <IconContainer>
        <MoneyKeys />
        <p><FormattedMessage id='NOT_FOUND_TRANSACTIONS' /></p>
      </IconContainer>
      <IconContainer>
        <Target />
        <p><FormattedMessage id='NOT_FOUND_CONVENIENCE' /></p>
      </IconContainer>
    </Container>
  );
};

const PageNotFound = () => {
  const { formatMessage } = useIntl();
  return (
    <NotFoundLayoutWrapper>
      <NotFoundLayout title={ formatMessage({ id: 'PAGE_NOT_FOUND_TITLE' }) } graphics={ <PageNotFoundGraphics /> } />
    </NotFoundLayoutWrapper>
  );
};

const Container = styled.div`
  ${flexAlignVerticalContent('center', 'center')}
  margin-top: 40px;
  ${mq(DESKTOP, `
    ${flexAlignHorizontalContent('center', 'center')}
  `)};
  text-transform: uppercase;
`;

const IconContainer = styled.div`
  ${typeStyle('body-s')}
  color: var(--cvna-color-grey-5);
  width: 181px;
  margin-bottom: 40px;

  svg {
    margin-bottom: 16px;
  }
`;

export default PageNotFound;
