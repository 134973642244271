import styled from 'styled-components';
import { typeStyle } from '@carvana/showroom-css-theme';
import Button from '@src/components/Button';
import {
  mq,
  LARGE_MOBILE,
  MOBILE,
  DESKTOP,
  flexAlignHorizontalContent,
  flexAlignVerticalContent
} from '../../styles/mixins';

export const ListingDetailContainer = styled.div`
  ${flexAlignHorizontalContent('center', 'start')};
  padding: 24px 32px;
  max-width: 1502px;
  margin: 0 auto;
  gap: 24px;

  ${mq(LARGE_MOBILE, `
    padding: 0;
    gap: 0;
    flex-direction: column;
  `)};
`;

export const LeftContainer = styled.div`
  ${flexAlignVerticalContent('flex-start', 'flex-start')}
  width: 100%;
  gap: 8px;

  ${mq(LARGE_MOBILE, `
    padding: 0 24px;
  `)};
`;

export const CTAHeader = styled.div`
  ${flexAlignHorizontalContent('space-between', 'center')}
  width: 100%;
`;

export const BackButton = styled.a`
  ${flexAlignHorizontalContent('space-between', 'center')}
  ${typeStyle('body-m')}
  gap: 8px;
  font-weight: 420;
  color: var(--cvna-color-blue-2);
  cursor: pointer;
  :hover {
    color: var(--cvna-color-blue-3);
  }
`;

export const ContentContainer = styled.div`
  ${flexAlignVerticalContent('normal', 'center')}
  gap: 16px;
  min-width: 640px;

  ${mq(LARGE_MOBILE, `
    min-width: 100%;
    width: 100%;
    padding: 24px;
    margin-bottom: 104px;
  `)};
`;

export const TopSection = styled.div`
  display: flex;
  background-color: var(--cvna-color-grey-1);
  padding: 24px;
  border-radius: 6px;
`;

export const BottomSection = styled.div`
  ${flexAlignVerticalContent('normal', 'center')}
  width: 100%;
  gap: 16px;
  padding: 0 20px;

  ${mq(LARGE_MOBILE, `
    padding: 0;
  `)};
`;

export const BidCard = styled.div`
  ${flexAlignVerticalContent('center', 'top')};
  width: 312px;
  height: fit-content;
  padding-top: 16px;
  box-shadow: var(--cvna-elevation-2);
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--cvna-color-white);

  // ProgressBar original styles
  & div:last-child {
    border-radius: 0 0 4px 4px;
    & span {
      border-radius: 0 0 4px 4px;
    }
  }

  ${mq(LARGE_MOBILE, `
    ${flexAlignHorizontalContent('space-between', 'center')};
    z-index: 100;
    padding: 16px 24px 8px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    filter: drop-shadow(0px -5px 13px rgba(24, 53, 88, 0.05));
    box-shadow: 0px -28px 56px rgba(24, 53, 88, 0.04), 0px -8px 16px rgba(24, 53, 88, 0.12);
  `)};

  ${mq(MOBILE, `
    padding: 16px 0 16px 16px;
    button {
      margin: 0 16px;
      min-width: auto;
      max-width: 250px;
    }
  `)};
`;

export const BidCardText = styled.div`
  font-size: 12px;
  color: var(--cvna-color-grey-5);

  ${mq(LARGE_MOBILE, `
    display: none;
  `)};
`;

export const NotFoundLayoutWrapper = styled.div`
  margin-top: 54px;
  ${mq(DESKTOP, `
    height: calc(100vh - var(--header-height));
    margin-top: 0;
  `)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SadCarWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 16px;
`;

export const ListingNotFoundMsg = styled.p`
  margin-bottom: 40px;
`;

export const ViewCRButton = styled(Button)`
  width: 100%;
  min-height: 40px !important;
  height: 40px;
`;
